<template>
  <div>
    <div class="subhead-text mb-8">Информация о пользователе</div>
    <v-row>
      <v-col
        cols="12"
        sm="3"
      >
        <v-text-field
          v-model="userDetails.last_name"
          outlined
          label="Фамилия"
          placeholder="Фамилия"
        ></v-text-field>
      </v-col>
      <v-col
        cols="12"
        sm="3"
      >
        <v-text-field
          v-model="userDetails.first_name"
          outlined
          label="Имя"
          placeholder="Имя"
        ></v-text-field>
      </v-col>
      <v-col
        cols="12"
        sm="3"
      >
        <v-text-field
          v-model="userDetails.email"
          outlined
          label="Email"
          placeholder="Email"
        ></v-text-field>
      </v-col>
      <v-col
        cols="12"
        sm="3"
      >
        <v-text-field
          v-model="userDetails.phone"
          outlined
          label="Телефон"
          placeholder="Телефон"
        ></v-text-field>
      </v-col>
      <v-col
        cols="12"
        sm="4"
        lg="4"
      >
        <v-autocomplete
          v-model="userDetails.user_role_id"
          :items="roles"
          item-text="name"
          item-value="id"
          :return-object="false"
          outlined
          label="Группа"
          placeholder="Выберите группу пользователя"
        ></v-autocomplete>
      </v-col>
      <v-col
        cols="12"
        sm="4"
        lg="4"
      >
        <v-autocomplete
          v-model="userDetails.companyIds"
          :items="companies"
          :multiple="isManagerDealership"
          small-chips
          item-text="name"
          item-value="id"
          :return-object="false"
          outlined
          clearable
          label="Компания"
          placeholder="Выберите Компанию пользователя"
          v-if="!isAdmin && !isApiUser"
          :disabled="!userDetails.user_role_id"
        >
          <template v-slot:selection="data">
            <v-chip
              v-bind="data.attrs"
              :input-value="data.selected"
              color="primaryshade"
              text-color="primary"
              label
              small
              :close="isManagerDealership"
              @click="data.select"
              @click:close="deleteCompany(data.item.id)"
            >
              {{ data.item.name }}
            </v-chip>
          </template>
        </v-autocomplete>
      </v-col>
      <v-col
        cols="12"
        sm="4"
        lg="4"
        v-if="isManagerDealership"
      >
        <v-autocomplete
          v-model="userDetails.brandIds"
          :items="brandMatches"
          multiple
          small-chips
          clearable
          :return-object="false"
          :search-input.sync="searchInput"
          item-text="name"
          item-value="id"
          outlined
          label="Какие марки ТС продает"
          placeholder="Выберите марку автомобиля"
        >
          <template v-slot:selection="data">
            <v-chip
              v-bind="data.attrs"
              :input-value="data.selected"
              color="primaryshade"
              text-color="primary"
              label
              small
              close
              @click="data.select"
              @click:close="deleteBrand(data.item.id)"
            >
              {{ data.item.name }}
            </v-chip>
          </template>
        </v-autocomplete>
      </v-col>

      <v-col
          v-if="isManagerDealership"
          cols="6"
          sm="12"
          lg="6"
          class="mb-4"
      >
        <div class="v-label">Какой тип ТС продает</div>
        <v-checkbox
            v-model="userDetails.type_technics.is_car"
            label="Легковой"
            hide-details
            class="d-inline-block lg"
        ></v-checkbox>
        <v-checkbox
            v-model="userDetails.type_technics.is_truck"
            label="Грузовой"
            hide-details
            class="d-inline-block lg ml-3"
        ></v-checkbox>
      </v-col>

      <v-col
          v-if="isManagerDealership"
          cols="6"
          sm="12"
          lg="6"
          class="mb-4"
      >
        <div class="v-label">Какое состояние ТС продает</div>
        <v-checkbox
            v-model="userDetails.type_technics.new_mileage"
            label="Новый"
            hide-details
            class="d-inline-block lg"
        ></v-checkbox>
        <v-checkbox
            v-model="userDetails.type_technics.with_mileage"
            label="С пробегом"
            hide-details
            class="d-inline-block lg ml-3"
        ></v-checkbox>
      </v-col>
      <v-spacer></v-spacer>
      <v-col
        v-if="(!isManagerDealership && isNotNewUser) || isApiUser"
        cols="12"
        sm="6"
        lg="6"
      >
        <v-text-field
          v-model="userDetails.password"
          outlined
          label="Пароль"
          placeholder="Пароль"
        ></v-text-field>
      </v-col>
      <v-col
        v-if="isManagerDealership && isNotNewUser"
        cols="12"
        sm="6"
        lg="6"
        class="d-flex flex-row"
      >
        <v-btn
          color="secondary"
          x-large
          width="250"
          depressed
          class="mx-auto"
          @click="changePassword"
        >
          Сменить пароль
        </v-btn>
        <v-text-field
          v-model="newPassword"
          outlined
          label="Новый пароль"
          placeholder="Здесь появится пароль"
          class="ml-8"
        ></v-text-field>
      </v-col>
      <v-col
        v-if="isNotNewUser"
        cols="12"
        sm="3"
        lg="3"
      >
        <v-autocomplete
          v-model="userDetails.user_status_id"
          :items="statuses"
          item-text="name"
          item-value="id"
          :return-object="false"
          outlined
          label="Статус"
        ></v-autocomplete>
      </v-col>
    </v-row>
    <v-row v-if="!isApiUser">
      <v-col>
        <div class="v-label">Уведомления</div>
        <v-checkbox
          v-model="userDetails.sms_on"
          label="SMS"
          hide-details
          class="d-inline-block lg"
        ></v-checkbox>
        <v-checkbox
          v-model="userDetails.email_on"
          label="Email"
          hide-details
          class="d-inline-block lg ml-3"
        ></v-checkbox>
      </v-col>
    </v-row>
    <div class="mt-3" v-if="tgData">
      <div><b>Telegram-бот</b></div>
      <div >
        <span>Регистрация: {{ formatDate(tgData.registered_at) }}</span>
        <span class="ml-3" v-if="tgData.nick">Ник пользователя: {{ tgData.nick }}</span>
        <span class="ml-3" v-if="!userDetails.tg_id">Отписался: {{ formatDate(tgData.unregistered_at) }}</span>
      </div>
    </div>
  </div>
</template>

<script>
// import Vue from 'vue'
// import Component from 'vue-class-component'
import _ from 'lodash'
// import { Prop, Watch } from 'vue-property-decorator'
import {
  Dealership,
  UserRole,
  LeasingCompany,
  Status,
  UserDetails,
  UserRoleId,
  CarBrand
} from '@/typings/AdminTyping'
import { User } from '@/apis/User'
import moment from 'moment'
/*
@Component({
  name: 'AdminUserForm'
})
*/
export default {
  /* @Prop(Array) roles!: UserRole[]
  @Prop(Array) brands!: CarBrand[]
  @Prop(Array) leasingCompanies!: LeasingCompany[]
  @Prop(Array) dealerships!: Dealership[]
  @Prop(Array) statuses!: Status[]
  @Prop(Object) userDetails!: UserDetails
  @Prop(Boolean) isNotNewUser!: boolean
   */
  props: [
    'roles',
    'brands',
    'leasingCompanies',
    'dealerships',
    'statuses',
    'userDetails',
    'isNotNewUser',
    'tgData'
  ],

  data() {
    return {
      newPassword: '',
      searchInput: '',
      brandMatches: this.brands
    }
  },

  watch: {
    searchInput(v) {
      this.querySelections(v)
    }
  },

  computed: {
    isAdmin() {
      return this.userDetails.user_role_id === UserRoleId.Admin
    },

    isApiUser() {
      return this.userDetails.user_role_id === UserRoleId.ApiUser
    },

    isManagerDealership() {
      return this.userDetails.user_role_id === UserRoleId.ManagerDealership
    },

    companies() {
      return _.indexOf([UserRoleId.SupervisorLeasingCompany, UserRoleId.ManagerLeasingCompany], this.userDetails.user_role_id) !== -1
        ? this.leasingCompanies
        : this.dealerships
    }
  },

  methods: {

    querySelections(v) {
      if (v !== null) {
        this.brandMatches = this.brands.filter((item) => item.name.toLowerCase().startsWith(v.toLowerCase()))
      } else {
        this.brandMatches = this.brands
      }
    },

    deleteBrand(brandId) {
      const index = this.userDetails.brandIds.indexOf(brandId)
      if (index !== -1) {
        this.userDetails.brandIds.splice(index, 1)
      }
    },

    deleteCompany(companyId) {
      const index = this.userDetails.companyIds.indexOf(companyId)
      if (index !== -1) {
        this.userDetails.companyIds.splice(index, 1)
      }
    },

    changePassword() {
      User.changePasswordManagerDs(this.$route.params.id)
        .then(({ data }) => {
          if (data.success === true) {
            this.newPassword = data.password
            this.showSuccessSystemNotification(data.msg)
          } else {
            this.showErrorSystemNotification(data.msg)
          }
        })
        .catch(() => {
          this.showErrorSystemNotification('Ошибка при смене пароля, обратитесь к разработчику')
        })
    },

    formatDate(date) {
      return date ? moment(date).format('LLL') : ''
    }
  }
}
</script>
